import { createApp } from 'vue'
import App from './App.vue'
import { applyPolyfills, defineCustomElements } from '@universityofbristol/site-search/loader';

// Bind the custom elements to the window object
applyPolyfills().then(() => {
    defineCustomElements();
});

//
const homepageSearchElement = document.querySelector('#homepage-search')

// Create Vue application
const app = createApp(App, {
    undergraduateUrl: homepageSearchElement.dataset.undergraduateSearchPage,
    postgraduateUrl: homepageSearchElement.dataset.postgraduateSearchPage,
    // single study year replaced by degree level study years
    // studyYear: homepageSearchElement.dataset.studyYear,
    undergraduateStudyYear: homepageSearchElement.dataset.undergraduateStudyYear,
    postgraduateStudyYear: homepageSearchElement.dataset.postgraduateStudyYear
});

// Mount application
app.mount(homepageSearchElement)