<style lang="scss">
@import "scss/main";
</style>
<template>
  <search-wrapper
    :cludo-customer-id="2541"
    :cludo-engine-id="11278"
    :initially-load-results="true"
    :key="studyLevel"
    :per-page="10"
    :search-url="searchUrl"
    :searchPayload.prop="searchPayload"
    ref="searchWrapper"
    query-stats-enabled="false"
  >
    <!-- Search Form -->
    <search-form>
      <div class="grid grid--33-66 grid--align-stretch">
        <label for="study-level" class="sr-only">Study level</label>
        <select
          v-model="studyLevel"
          id="study-level"
          name="StudyLevel"
          class="form-control form-control--large"
        >
          <option value="undergraduate">Undergraduate</option>
          <option value="postgraduate_taught">Postgraduate taught</option>
          <option value="postgraduate_research">Postgraduate research</option>
        </select>
        <div class="form-control form-control--large form-control--search">
          <div
            :class="[
              'search-dropdown',
              showDropdown ? 'search-dropdown--results' : '',
            ]"
          >
            <search-input
              :inputName.prop="inputName"
              :search-as-you-type="true"
              placeholder="Course name, keyword or UCAS Code..."
              search-dropdown="results"
            ></search-input>
            <search-results-dropdown
              v-if="searchTerm.length > 3"
              ref="search-results-dropdown"
              :searchResultRenderer.prop="searchResultRenderer"
            ></search-results-dropdown>
          </div>
          <button class="button button--outline bg-white" type="submit">
            <span class="sr-only">Search</span>
            <i class="icon-search" aria-hidden="true"></i>
          </button>
        </div>
        <input
          v-if="studyType"
          type="hidden"
          name="filterStudyType"
          :value="studyType"
        />
        <!-- Note the following input is relevant only to Solr search - it does not appear to impact on Cludo, but should be removed once Solr is abandoned -->
        <input
          v-if="studyLevel == 'undergraduate'"
          type="hidden"
          name="filter:UndergraduateCourse-year"
          :value="undergraduateStudyYear"
        />
      </div>
    </search-form>
  </search-wrapper>
</template>
<script>
import { createApp } from "vue";
import SearchResult from "./components/SearchResult.vue";

export default {
  name: "App",
  data() {
    return {
      loadingResults: false,
      resultsCount: 0,
      searchTerm: "",
      studyLevel: "undergraduate",
    };
  },
  props: {
    undergraduateUrl: {
      type: String,
      default: window.location,
    },
    postgraduateUrl: {
      type: String,
      default: window.location,
    },
    // single study year replaced by degree level study years
    // studyYear: {
    //   type: String,
    //   default: "",
    // },
    undergraduateStudyYear: {
      type: String,
      default: "",
    },
    postgraduateStudyYear: {
      type: String,
      default: "",
    },
  },
  computed: {
    /*
     * Returns whether to show the search results dropdown
     *
     * @returns {boolean}
     */
    showDropdown() {
      return this.resultsCount > 0 && this.searchTerm.length > 3;
    },

    /*
     * Returns the study type based on the study level
     *
     * @returns {string}
     */
    studyType() {
      if (
        this.studyLevel == "postgraduate_taught" ||
        this.studyLevel == "postgraduate_research"
      ) {
        return this.studyLevel == "postgraduate_taught" ? "Taught" : "Research";
      }

      return null;
    },

    /*
     * Returns the input name based on the study level
     *
     * @returns {string}
     */
    inputName() {
      return this.studyLevel == "undergraduate" ? "query" : "q";
    },

    /*
     * Returns the current window location pathname
     *
     * @returns {string}
     */
    searchUrl() {
      return this.studyLevel == "undergraduate"
        ? this.undergraduateUrl
        : this.postgraduateUrl;
    },

    // Note the below originally just returned this.studyYear - but expanded to allow for multiyear UG solr to run alongside
    // a PG search set for differing study years
    studyYearFilters() {
      if (
        this.undergraduateStudyYear &&
        this.postgraduateStudyYear &&
        this.studyLevel
      ) {
        if (this.studyLevel === "undergraduate") {
          return {
            StudyYear: [this.undergraduateStudyYear],
          };
        } else {
          return {
            StudyYear: [this.postgraduateStudyYear],
          };
        }
      }

      return {};
    },

    studyLevelFilters() {
      if (this.studyLevel == "undergraduate") {
        return {
          StudyLevel: ["Undergraduate"],
        };
      } else {
        return {
          StudyLevel: ["Postgraduate"],
          StudyType: [this.studyType],
        };
      }
    },

    /*
     * Returns the search payload to provide filters that are executed with the query
     *
     * @returns {string}
     */
    searchPayload() {
      return {
        filters: {
          Category: ["Course"],
          ...this.studyLevelFilters,
          ...this.studyYearFilters,
        },
      };
    },
  },
  methods: {
    /*
     * Overrides the default search result renderer by compiling a local Vue template
     *
     * @returns {string}
     */
    searchResultRenderer(cludoSearchResult) {
      return createApp(SearchResult, {
        searchResult: cludoSearchResult,
      }).mount(document.createElement("div")).$el.outerHTML;
    },
  },
  mounted() {
    // Listen for `searchTerm` events and update local component data
    this.$refs.searchWrapper.addEventListener("searchTerm", (event) => {
      this.searchTerm = event.detail;
    });

    // Listen for `searchResponse` events and update local component data
    this.$refs.searchWrapper.addEventListener("searchResponse", (event) => {
      this.resultsCount = event.detail.TotalDocument;
    });

    // Listen for `selectedSearchResult` events and update local component data
    this.$refs.searchWrapper.addEventListener(
      "selectedSearchResult",
      (event) => {
        const searchResultsDropdown =
          this.$refs["search-results-dropdown"].firstChild;
        const selectedSearchResult = this.$refs[
          "search-results-dropdown"
        ].querySelector(`#result-${event.detail}`);

        if (selectedSearchResult) {
          // Set scroll top so search result is "in the middle" of the dropdown
          searchResultsDropdown.scrollTop =
            selectedSearchResult.offsetTop -
            searchResultsDropdown.clientHeight / 2 +
            selectedSearchResult.clientHeight / 2;
        } else {
          searchResultsDropdown.scrollTop = 0;
        }
      }
    );
  },
};
</script>
